<template>
  <div>
    <ul class="vouchers">
      <li class="flex-container v-li">
        <!-- <img class="icon" src="@image/order/order_vouchers.png" /> -->
        <div class="icon2">券</div>
        <span class="title flex1">优惠券</span>
        <!-- <span class="dec">当前有3张优惠券可用</span> -->
        <i class="price" @click="showCouponShow = true">
          {{
            thisVouchers && thisVouchers.voucher_price
              ? `-￥${thisVouchers.voucher_price}`
              : "不使用"
          }}
        </i>
        <img
          class="more"
          src="@image/goods/review_more.png"
          @click="showCouponShow = true"
        />
      </li>
    </ul>
    <van-popup
      v-model="showCouponShow"
      round
      closeable
      position="bottom"
      style="height: 80%; "
    >
      <oCoupon
        :vouchers="vouchers"
        :boo.sync="showCouponShow"
        @enter="getSelectCoupon"
      ></oCoupon>
    </van-popup>
  </div>
</template>

<script>
import { Switch, Popup, Toast } from "vant";
import oCoupon from "../../buy/components/vouchers/components/coupon";
export default {
  props: {
    vouchers: {},
    voucher: {},
  },
  components: {
    vanSwitch: Switch,
    oCoupon,
    vanPopup: Popup,
  },
  mounted() {},
  watch: {
    vouchers() {
      if (this.vouchers && this.vouchers.length != 0) {
        this.thisVouchers = this.vouchers[0];

        this.$emit("update:voucher", this.vouchers[0]);
      }
    },
  },
  created() {},
  methods: {
    getSelectCoupon(item) {
      this.thisVouchers = item;
      this.$emit("update:voucher", item);
    },
  },
  data() {
    return {
      thisVouchers: {},
      showCouponShow: false,
      checked: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.vouchers {
  background: #fff;
  width: rem(710);
  margin: 0 auto rem(24);
  border-radius: rem(16);
  padding: rem(20);

  box-sizing: border-box;
  .v-li {
    align-items: center;
    justify-content: center;
    margin-bottom: rem(30);
    &:last-of-type {
      margin-bottom: 0;
    }
    .icon {
      width: rem(30);
    }
    .icon2 {
      width: rem(32);
      height: rem(32);
      /* 商城渐变 */
      text-align: center;
      line-height: rem(32);
      background: linear-gradient(90deg, #ff6666 0%, #ff4848 100%);
      border-radius: rem(12) rem(6);
      font-weight: 600;
      font-size: rem(20);
      /* identical to box height */

      color: #ffffff;
    }
    .switch {
      // margin-right: rem(20);
    }

    .title {
      margin-left: rem(10);
      font-size: rem(28);
      font-weight: 600;
      color: #222;
    }
    .dec {
      font-weight: 400;
      font-size: rem(28);
      line-height: rem(39);

      color: #999999;
    }
    .price {
      color: #ff4848;
      font-size: rem(28);
      margin-right: rem(20);
    }
    .more {
      width: rem(23);
    }
  }
}
</style>
