<template>
  <div class="remarks">
    <span class="t">订单备注</span>
    <input type="text" placeholder="选填,请先和商家协商一致" class="input t" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

.remarks {
  background: #fff;
  width: rem(710);
  margin: 0 auto rem(24);
  border-radius: rem(16);
  padding: rem(20);
  box-sizing: border-box;
  display: flex;
}
.t {
  font-weight: 400;
  font-size: rem(28);
  line-height: rem(39);
  color: #222222;
}
.input {
  flex: 1;
  text-align: right;
  padding: 0 rem(24);
  color: #222;
}

.input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #999;
}
.input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #999;
}
.input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999;
}
.input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999;
}
</style>
