<!--
 * @name: 
 * @test: test font
 * @msg: 
 * @param: 
 * @return: 
-->
<template>
  <div>
    <!-- <oAddress :addressArr.sync="addressArr"></oAddress> -->
    <oProduct
      ref="product"
      state="step2"
      :goods="goods"
      class="step2-product"
    ></oProduct>
    <!-- <oBalance></oBalance> -->
    <!-- <oVouchers></oVouchers> -->
    <!-- <oPay
      @errorEmit="errorEmit"
      :voucher="voucher"
      :len="len"
      :form="form"
      :cartForm="cartForm"
      :balancePayState="balancePayState"
      :remarks="remarks"
      state="step2"
    ></oPay> -->
    <!-- <oRemarks></oRemarks> -->
  </div>
</template>

<script>
import oAddress from "../buy/components/address";
import oProduct from "./components/product.vue";
import oBalance from "./components/balance";
import oVouchers from "./components/vouchers";
import oPay from "./components/pay";
import oRemarks from "./components/remarks";
import { post } from "@get/http";
export default {
  components: {
    oAddress,
    oRemarks,
    oProduct,
    oBalance,
    oVouchers,
    oPay,
  },
  data() {
    return {
      addressArr: [],
      remarks: "", //用户备注
      form: {}, //请求的数据
      cartForm: [], //购物车数据
      voucher: {}, //代金券
      len: 0, //产品数量
      goods: {}, //商品
      balancePayState: false, //是否零钱支付
      errorText: "",
    };
  },
  methods: {
    errorEmit(data) {
      this.errorText = data;
    },
  },
  async created() {
    {
      let data = await post(
        "/v3.MemberPresaleGoods/getPresaleGoodsDepositOrderList",
        {
          params: {
            status: 1,
            pageSize: 50,
            page: 1,
          },
        }
      );
      this.goods = data.goods;
    }
  },
  mounted() {
    // console.log(this.$refs.product.$refs);
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.step2-product {
  margin-top: rem(24);
  padding-bottom: rem(100);
}
</style>
